.Text-InputView {
    min-height: calc(100vh - 470px);
    width: 90%;
    height: 100%;
}


.ForgotPassword{
    color: #FF9E1B;
    font-family: "Manrope";
    font-weight:600;
    font-size: 14px;
    margin-bottom: 20px;
 }

.MatrialTextFieldFull .MuiOutlinedInput-root{
    border-radius: 4px;
    margin-bottom: 20px;
 }
 .MatrialTextFieldFull input{
    height: 18px;

 }
 .MatrialTextFieldFull .MuiOutlinedInput-notchedOutline{
    border-color: #6B7278;
 }
 .RightView{
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
 }
 .CenterView{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
 }
 .BoxView{
   width: 100%;
   /* background-color: antiquewhite; */
   border-width: 1px;
   border-color: #8A959E;
   border-style: solid;
   border-radius: 10px;
   height: 350px;
   overflow-y: scroll;

   /* min-height: 300px; */
 }
.title{
    font-family: 'Manrope';
    font-weight: 800;
    font-size: 17px;
 }
.RowView{
   display: flex;
   flex-direction: row;
   /* justify-content: center; */
}
.SelectBox{
   margin: 5px;
   width: 20px;
   height: 20px;
   border-style: solid;
   border-radius: 5px;
   border-color:  #8A959E;

}
.SelectZone-min-hight {
   min-height :calc(100vh - 270px);
   align-items: center;
   width: 100%;
}

.itemDiv {
   width: 95%;
   text-align: center;
   border-bottom: 1px solid #000;
   line-height: 0.1em;
   margin: 10px 0 20px;
}

.itemDiv span {
    background:#fff;
    padding:0 10px;
}
