.popUpBasicContrainer {
    /* margin-left: 10px; */
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgb(0,0,0,0.3);
    top: 0;
}

.pop-up{
    /* position: absolute;
    bottom: 0; */
    align-self: center;
    width: 100%;
    border-top-left-radius: 28px;
    border-top-right-radius: 28px;
    background-color: white;
    max-width: 480px;
}

.minus-button{
    margin-top: 20px;
    width: 49px;
    height: 4px;
    border-radius: 2px;
    background-color: #E0E0E0;
}
.close-icon{
    height: 17.97px;
    width: 17.97px;
    padding: 10px;
    margin-right: 10px;
}
.pop-up-title{
    font-family: 'Manrope';
    font-weight: 700;
    font-size: 15px;
}
.pop-up-sub-title{
    font-family: 'Manrope';
    font-weight: 400;
    font-size: 15px;
}
.start-icon{
    margin:10px;
    height: 33.33px;
    width: 33.33px;
}
.short-middle-line{
    margin: 10px;
    width: 50px;
    height: 2px;
    background-color: #00AF85;
}
.TF-container-view {
    /* width: 480px; */
    width: 90%;
    /* background-color: rgb(104, 158, 24); */
    /* height: 200px; */
}
.dropDown-label-1{
    box-shadow: 0px 0.5px 2px 0.5px rgb(0 0 0 / 0.1);
    padding-left: 16px ;    
    padding-top: 7.5px;
    padding-bottom: 7.5px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    margin-top: -10px;
    margin-right: 6px;
    margin-left: 6px;
    color: #111012;
    touch-action: auto;
}
.dropDown-label-2{
    box-shadow: 0px 0.5px 2px 0.5px rgb(0 0 0 / 0.1);
    padding-left: 16px ;    
    padding-top: 7.5px;
    padding-bottom: 7.5px;
    margin-top: 1px;
    margin-right: 6px;
    margin-left: 6px;
    color: #111012;
    font-weight: 400;
    line-height: 28px;
}
.dropDown-label-3{
    box-shadow: 0px 1px 2px 0.5px rgb(0 0 0 / 0.1);
    padding-left: 16px ;    
    padding-top: 7.5px;
    padding-bottom: 7.5px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    margin-top: 1px;
    margin-right: 6px;
    margin-left: 6px;
    color: #111012;
}
.dropDown-center{
        position: absolute;
        top: 32%;
        left: 93%;
        transform: translate(-10%, -50%);
      
}
.dropdown-container {
    position: relative;
    text-align: right;
    color: white;
  }

