.slogan-container {
    width: 100%;
    text-align: center;
    /* background-color: rebeccapurple; */
}

.slogan-title {
    font-family: "Manrope";
    font-weight: 400;
    font-size: 15px;

    /* background-color: aliceblue; */
}

.min-hight {
    /* min-height :calc(100vh ); */
    height: 300px;
    width: 100%;


    /* align-items: center;
    width: 100%; */
}

.warning-title {
    display: flex;
    color: #DC2626;
    font-family: 'Manrope';
    font-weight: 400;
    font-size: 12px;
    margin-top: -10px;
}
