.row-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.title-header {
  font-family: "Manrope";
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  width: 100%;
}

.title-container {
  width: 100%;
  text-align: center;
}

.coloum-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.logo-icon {
  height: 62px;
  width: 200px;
  margin-left: 10px;
}

.Back-Icon {
  height: 16px;
  width: 16px;
}

.LineGradient {
  height: 5px;
  background: linear-gradient(rgba(174, 172, 172, 0.5), transparent);
  background-color: rgb(251, 250, 249)
}
