.Spinner_Screen{
    display: flex;
    flex-direction: column;
    position: fixed;
    margin-top: -70px;
    width: 100%;
    height: 100%;
    justify-content: center;
    z-index: 2;
}
.SubView{
    /* background-color: orange; */
    text-align: center;
}