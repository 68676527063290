.Text-InputView {
   min-height: calc(100vh - 470px);
   width: 90%;
   height: 100%;
}


.ForgotPassword {
   color: #FF9E1B;
   font-family: "Manrope";
   font-weight: 600;
   font-size: 14px;
   margin-bottom: 20px;
}

.BaseContainer {
   align-items: center;
   display: flex;
   flex-direction: column;
   width: 85%;
}

.Sub-Container-View {
   width: 100%;
   /* background-color: rgb(195, 104, 90); */
   height: 100vh;
   display: flex;
   flex-direction: column;
   flex: 1;
}

.MatrialTextFieldFull .MuiOutlinedInput-root {
   border-radius: 4px;
   margin-bottom: 20px;
}

.MatrialTextFieldFull input {
   height: 18px;
   border-radius: 4px;
}

.MatrialTextFieldFull .MuiOutlinedInput-notchedOutline {
   border-color: #6B7278;
}

.RightView {
   display: flex;
   flex-direction: row-reverse;
}

.LeftView {
   display: flex;
   flex-direction: row;
}

.FieldLabel {
   font-family: Manrope;
   font-size: 14px;
   font-weight: 600;
   line-height: 20px;
   letter-spacing: 0;
   text-align: left;
   color: #6B7278;
   padding: 12px;
;
}
