
.Root-Container{
  /* background-color: yellow; */
  display: flex;
  flex-direction: column;
  align-items: center;
  overscroll-behavior-x: none;
 
}
 .Max-Width {
  /* background-color:red; */
  max-width: 480px;
  width:100%;
  /* height: 600px; */
  /* background-color: red; */
  /* width: 50%; */
}