.Alert_Screen{
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 100%;
    height: 100%;
    justify-content: center;
    background-color: rgba(0, 0, 0, .5);
    z-index: 2;
    align-items: center;
    align-self: flex-end;
}

.popup{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    width: 90%;
    /* height: 250px; */
    border-radius: 12px;
    background-color: white;
    margin-bottom: 20%;
    /* padding-bottom: 10%; */

}

.AlertSubTitle{
    font-family: "Manrope";
    font-weight: 400;
    font-size: 15px;
    text-align: center;
    color: #323232;
    height: 90px;
    width: 80%;
}

.Title{
    margin-top: 10px;
    font-family: "Manrope";
    font-weight: 800;
    font-size: 15px;
}
.DualButton{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content:space-evenly;
    margin-bottom: 10px;
}
.SingleButton{

    /* margin-top: 40px; */
    width: 40%;
    margin-bottom: 10px;

}
.BottomTitle{
    /* padding: 10px; */
    /* margin: 0px; */
    font-family: "Manrope";
    font-weight: 400;
    font-size: 15px;
    text-align: center;
    color: #00AF85;
    text-decoration: underline;
    /* margin-bottom: 30px; */

}
