.ReportPopUpBasicContrainer {
    /* margin-left: 10px; */
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgb(0,0,0,0.3);
}
.DurationParentTypeView {
    /* width: 100%;
    margin-left: 10px;   
    height: 58px;
    border-radius: 15px; */
  
}
.inputRounded.MuiOutlinedInput-root{
    border-radius: 12px;
 }