.network-status {
  display: grid;
  place-content: center;
  color: #000;
  background-color: #ffc235;
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
  transition: margin-top 0.5s cubic-bezier(0.18, 1, 0, 1), visibility 0.5s linear;
  height: 24px;
  margin-top: -24px;
  visibility: hidden;
}

.network-status.offline {
  margin-top: 0;
  visibility: visible;
}

.network-status.syncing {
  margin-top: 0;
  visibility: visible;
  background-color: #0f0;
}

.network-status + .adjust-screen {
  height: 100vh;
}

.network-status.offline + .adjust-screen, .network-status.syncing + .adjust-screen {
  height: calc(100vh - 24px) !important;
}

.network-status button {
  appearance: none;
  background-color: hsl(0 0% 100% / 0.5);
  border: 1px solid gray;
  border-radius: 0.25rem;
  text-transform: uppercase;
  font-weight: 600;
}
