.RowViewSpaceBetween {
   display: flex;
   width: 100%;
   /* width: 1000px; */
   flex-direction: row;
   justify-content: space-between;
}

.SubTitle {
   font-family: 'Manrope';
   font-weight: 500;
   font-size: 12px;
   color: '#111012';

}

.ListOfParkers-min-hight {
   min-height: 92px;
   align-items: center;
   width: 100%;
   overflow-y: scroll;
}

.truncate {
   text-align: center;
   font-family: 'Manrope';
   font-weight: 500;
   font-size: 12px;
   width: 70px;
   color: '#111012';
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2;
   /* number of lines to show */
   line-clamp: 2;
   -webkit-box-orient: vertical;
}
.GrayLabel{
   /* color: '#6B7278';  */
   font-family: 'Manrope';
   font-weight: 400;
   font-size: 16px;
   color: #6B7278;
 }

.GrayHeader{
   /* color: '#6B7278';  */
   font-family: 'Manrope';
   font-weight: 700;
   font-size: 16px;
   line-height: 20px;
   color: #6B7278;
;
}
 .GreenLabel{
   font-family: 'Manrope';
   font-weight: 400;
   font-size: 12px;
   color: #00AF85;
 }
